import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
// import EventCard from '../components/events/card'
import CategoryNav from '../components/categories/nav'
import { Container, Row, Col } from 'react-bootstrap'
import ArrowLink from '../components/misc/arrow-link'
import LayoutContext from '../components/layout/context'

class SchoolsIndex extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)

    this.state = {
      schools: get(props, 'data.allContentfulSchool.edges'),
    }
  }

  componentWillMount() {
    this.context.set({
      meta: get(this, 'props.data.contentfulMetaTags'),
    })
  }

  render() {
    const { schools } = this.state

    return (
      <>
        <Container className="py-5">
          <Row>
            <Col lg="3" className="pb-3 pb-lg-0">
              <h3 className="hu h2">HBCU Partners</h3>
            </Col>
            <Col>
              {schools.map(({ node }) => {
                return (
                  <div key={node.slug} className="list-item-w-divider">
                    <Row className="align-items-center">
                      <Col md="3">
                        <img
                          src={node.logo.file.url}
                          width={200}
                          alt={node.name}
                        />
                      </Col>
                      <Col>
                        {/* <div
                            dangerouslySetInnerHTML={{
                              __html: node.teaser.childMarkdownRemark.html,
                            }}
                            className="py-4"
                          /> */}
                        <h3 className="hu h3 mb-2 mt-3 mt-md-0">{node.name}</h3>
                        {node.copy ? (
                          <ArrowLink
                            color="000"
                            to={`/hbcu-partners/${node.slug}`}
                          >
                            Read More
                          </ArrowLink>
                        ) : (
                          <a key={node.slug} href={node.url} target="_blank">
                            {node.url}
                          </a>
                        )}
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Col>
          </Row>
        </Container>
        <CategoryNav />
      </>
    )
  }
}

export default SchoolsIndex

export const pageQuery = graphql`
  query SchoolIndexQuery {
    contentfulMetaTags(pagePath: { eq: "/hbcu-partners" }) {
      title
      image {
        gatsbyImageData(width: 1200, height: 630)
      }
      url
      description {
        description
      }
    }
    allContentfulSchool(
      filter: {
        contentful_id: { ne: "5qXVQWEerwQDygLHj9QNAo" }
        partner: { eq: true }
      }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          slug
          url
          copy {
            raw
          }
          logo {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
      }
    }
  }
`
